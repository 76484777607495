import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import {ErrorFallback,GlobalContext} from 'smart-react';
import './dashboardStyle.scss';
import {
  StackLayout,
  Avatar,
  Card,
  CardHeader,
  CardTitle,
  CardSubtitle,
  CardImage,
  CardBody,
  CardActions,
} from "@progress/kendo-react-layout";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
/**
 * Users main screen
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The Users component.
 */
const AddUser = () => {
    const { dimensions, deviceInfo } = React.useContext(GlobalContext);
    const [isHorizontal, setIsHorizontal] = React.useState(true);
    const data = [
      { title: 'Customer', value: 66 },
      { title: 'Autest', value: 1956 },
      { title: 'Products', value: 4 },
      { title: 'Organisation', value: 8 }
    ];

  
    React.useEffect(() => {
      console.log(dimensions);
      console.log(deviceInfo);
      }, [dimensions, deviceInfo]);
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    > 
    <GridLayout
        gap={{
          rows: 6,
          cols: 10,
        }}
        rows={[
          {
            height: 20,
          },
          {
            height: 100,
          },
          {
            height: 100,
          },
          {
            height: 20,
          },
          {
            height: 660,
          },
        ]}
        cols={[
          {
            width: 270,
          },
          {
            width: 270,
          },
          {
            width: 310,
          },
        ]}
      > 
    
      {data.map((item, index) => (
          <GridLayoutItem row={1} col={index} key={index}>
          <div key={index} className="statistics-card">
            <Card style={{ marginBottom: '30px' }}>
              <CardBody>
                <h3 className="card-title">{item.title}</h3>
                <div className="card-value">{item.value}</div>
              </CardBody>
            </Card>
          </div>
        </GridLayoutItem>
      ))}
   
    </GridLayout>
    </ErrorBoundary>
  );
};
export default AddUser;
