import { apiCall } from "../../../Middleware/API/apiRequest";
import { getTokensFromStorage } from "../../../Utils/Auth/AuthToken";
import { AUTEST_TEST } from "../../../constants/apiRoutes";

/**
 * list Tests.
 * @returns {Promise} - A promise that resolves with the list of Tests or rejects with an error.
 * @param props
 */
export const listTest = async ({
  accessToken,
  limit,
  offset,
  filter,
  sort,
  moduleName,
  isServiceWorker,
  listController,
}) => {
  const data = {
    Filters: filter,
    pageNumber: offset == 0 ? 1 : offset,
    pageSize: limit,
    sort,
  };

  const response = await apiRequest({
    url: `${AUTEST_TEST}/filter`,
    method: "POST",
    moduleName,
    isServiceWorker,
    listController,
    body: data,
    accessToken,
  });
  return {
    tests: response.Payload,
    TotalCount: response?.TotalRecordCount,
  };
};

/**
 * Create Test.
 * @returns {Promise} - A promise that resolves with the creation of test or rejects with an error.
 * @param data
 */
export const createTest = async ({
  data,
  moduleName,
  isServiceWorker,
  listController,
}) => {
  const { accessToken } = getTokensFromStorage();
  const response = await apiRequest({
    url: `${AUTEST_TEST}`,
    method: "POST",
    moduleName,
    isServiceWorker,
    listController,
    body: data,
    accessToken,
  });
  return response;
};

/**
 * Update Test.
 * @returns {Promise} - A promise that resolves with the update of test or rejects with an error.
 * @param data
 */
export const updateTest = async ({
  data,
  moduleName,
  isServiceWorker,
  listController,
}) => {
  const { accessToken } = getTokensFromStorage();
  const response = await apiRequest({
    url: `${AUTEST_TEST}/${data?.tenant_id}`,
    method: "PUT",
    moduleName,
    isServiceWorker,
    listController,
    body: data,
    accessToken,
  });
  return response;
};

/**
 * Update Test.
 * @returns {Promise} - A promise that resolves with the update of test or rejects with an error.
 * @param data
 */
export const deleteTest = async ({
  data,
  moduleName,
  isServiceWorker,
  listController,
}) => {
  const { accessToken } = getTokensFromStorage();
  const response = await apiRequest({
    url: `${AUTEST_TEST}/${data?.uc_ossi_app_test_id}?uc_ossi_prod_id=${data?.uc_ossi_prod_id}&tenantid=${data?.tenant_id}&uc_ossi_app_test_id=${data?.uc_ossi_app_test_id}`,
    method: "DELETE",
    moduleName,
    isServiceWorker,
    listController,
    body: data,
    accessToken,
  });
  return response;
};

/**
 * User Api Call Wrapper Method.
 * @param {object} api - Contains details like url headers method and data.
 * @returns {Promise} - A promise that resolves with the data of user or rejects with an error.
 */
const apiRequest = async (request) => {
  let response;
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };
  request.headers = headers;
  try {
    response = await apiCall(request);
  } catch (e) {
    response = { isSuccess: false, message: e.message };
  }
  return response;
};
